import * as React from "react"
import { graphql } from "gatsby"
// import Layout from "../components/layout"
import Seo from "../components/seo"
import imageUrlBuilder from "@sanity/image-url"
import clientConfig from "../../client-config"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const ProductPage = ({ data }) => {
  const products = data.products.nodes

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <div className="bg-white dark:bg-zinc-900 pt-1 pb-6">
      <Seo
        title="Projects"
        description="Inventions, installations, products and projects at the intersection of art and technology 💭"
      />
      {/* Blog Posts */}
      <section
        className="container mx-auto sm:my-8"
        style={{
          backgroundImage:
            "url(https://cdn.sanity.io/images/rbhjt84j/production/23d47bc59bacb258d3c7dbe95a0f7f2c1f8d2bef-1970x1212.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className=" px-8 py-12 bg-black/[0.6] text-white sm:shadow-lg">
          <h1 className="lg:text-5xl md:text-3xl text-2xl font-bold mb-4 ">
            To inspire + realize{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-green-400 to-pink-600">
              imagination
            </span>
          </h1>
          <p className="text-sm md:text-lg max-w-prose">
            Our work combines technology and art to inspire and realize
            imagination. We use advanced manufacturing techniques such as 3D
            printing, laser cutting, and CNC machining to bring imagination to
            life.
          </p>
        </div>
      </section>
      <section className="container mx-auto grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 w-full lg:p-0 p-2">
        {/* <img src="/cabinet.png" alt="Stylized version of the words: cabinet of curiosities" className="w-1/2 mx-auto mt-8"/> */}
        {products.map(
          (posts, index) =>
            posts.slug !== null &&
            posts.visible !== true && (
              <AniLink
                to={"/projects/" + posts.slug.current}
                className="text-zinc-300 shadow-lg dark:text-zinc-300 card-zoom"
                key={index}
                paintDrip
                duration={0.5}
                hex={
                  "#" + posts.category.color !== null
                    ? posts.category.color
                    : "15b8a6"
                }
              >
                <div
                  style={{
                    backgroundImage:
                      "url(" +
                      urlFor(posts.mainImage).width(800).auto("format").url() +
                      ")",
                  }}
                  className="inline-block card-zoom-image"
                ></div>
                <div className="p-2 bg-black/[0.65] hover:bg-black/[0.2] transition-all duration-500 ease-in-out transform absolute w-full h-full flex items-start flex-col justify-center text-white/[0.95]">
                  {posts.category && (
                    <span
                      style={{ background: posts.category.color }}
                      className="rounded text-[0.5rem] tracking-widest font-light uppercase justify-self-start text-white px-1 mb-2"
                    >
                      {posts.category.title}
                    </span>
                  )}
                  {/* <span className="flex-shrink mr-1 font-bold uppercase tracking-widest text-[0.5rem]">Project</span> */}
                  <h3 className="font-bold uppercase tracking-widest text-2xl">
                    {posts.title}
                  </h3>
                  <p className="text-sm font-light">{posts.excerpt}</p>
                </div>
              </AniLink>
            )
        )}
      </section>
    </div>
  )
}

export default ProductPage

export const query = graphql`
  query productPageB {
    products: allSanityProducts(
      sort: { fields: [title], order: ASC }
      filter: { _id: { glob: "!drafts*" }, visible: { ne: true } }
    ) {
      nodes {
        _createdAt
        _id
        mainImage {
          asset {
            url
          }
        }
        category {
          color
          title
        }
        title
        slug {
          current
        }
        excerpt
        visible
      }
    }
  }
`
